import React, {useEffect, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {Sprite, Stage} from 'react-pixi-fiber/index';
import * as PIXI from 'pixi.js';
import './Playback.css';
import {
  classMap,
  colourMap,
  CurrentFrame, denormalisePoint,
  FetchDTFs,
  Frame,
  FrameWorkerRequestMessage,
  FrameWorkerResponseMessage, getSnappiHost, isLocal, Pause,
  Play, SetSpeed, SetDate
} from "./utils";
import DetectionBox from "./DetectionBox";
import Loading from "./Loading";

const roundDate = (d: Date): Date => {
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)
  return d
}

function Playback() {
  const [speed, setSpeed] = React.useState<number>(1);
  const [newTimestamp, setNewTimestamp] = React.useState<string>("");
  const [currentFrame, setCurrentFrame] = React.useState<Frame | null>(null);
  const [imageUrl, setImageUrl] = React.useState<string>("");
  const [error, setError] = React.useState<string>("");

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const frameWorker: Worker = useMemo(
    () => new Worker(new URL("./frameWorker.ts", import.meta.url)),
    []
  );

  useEffect(() => {
    frameWorker.onmessage = (e: MessageEvent<CurrentFrame>) => {
      const payload = e.data;
      switch (payload.type) {
        case FrameWorkerResponseMessage.currentFrame:
          const currentFramePayload = payload as CurrentFrame;
          setCurrentFrame(currentFramePayload.frame)
      }
    }
  }, []);

  // TODO: fix me
  // useEffect(() => {
  //   const updateParams = () => {
  //     if (!currentFrame) {
  //       return
  //     }
  //     const currentUrl = new URL(window.location.href);
  //     currentUrl.searchParams.set("speed", speed.toString(10));
  //     currentUrl.searchParams.set("timestamp", currentFrame.timestamp.toISOString());
  //     window.history.replaceState({}, '', currentUrl);
  //
  //   }
  //   const int = setInterval(updateParams, 1000)
  //   return () => clearInterval(int)
  // }, [speed, currentFrame]);

  useEffect(() => {
    const setSpeedPayload: SetSpeed = {
      type: FrameWorkerRequestMessage.setSpeed,
      speed
    }
    frameWorker.postMessage(setSpeedPayload)
  }, [speed]);

  useEffect(() => {
    const vpid = getVpidFromURL()
    if (!vpid) {
      return
    }
    const d = getDateFromURL()
    if (!d) {
      return
    }
    const setDatePayload: SetDate = {
      type: FrameWorkerRequestMessage.setDate,
      date: d
    }
    frameWorker.postMessage(setDatePayload)

    const fetchFramesPayload: FetchDTFs = {
      type: FrameWorkerRequestMessage.fetchDTFs,
      vpid: vpid,
      date: roundDate(d),
    }
    frameWorker.postMessage(fetchFramesPayload)
  }, []);

  const getVpidFromURL = (): number | null => {
    const vpidString = query.get("vision_program_id")
    if (!vpidString) {
      setError("No vision_program_id provided")
      return null
    }
    const vpid = parseInt(vpidString, 10)
    if (isNaN(vpid)) {
      setError(`vision_program_id is not a number: ${vpidString}`)
      return null
    }
    return vpid
  }

  const getDateFromURL = (): Date | null => {
    const tsString = query.get("timestamp")
    if (!tsString) {
      setError("No timestamp provided")
      return null
    }
    const d = new Date(tsString)
    if (isNaN(d.getTime())) {
      setError(`timestamp is not valid: ${tsString}`)
      return null
    }
    return d
  }

  useEffect(() => {
    const ts = getDateFromURL()
    if (ts) {
      setNewTimestamp(ts.toISOString())
    }
  }, []);

  const play = () => {
    const playPayload: Play = {
      type: FrameWorkerRequestMessage.play
    }
    frameWorker.postMessage(playPayload)
  }

  const pause = () => {
    const pausePayload: Pause = {
      type: FrameWorkerRequestMessage.pause
    }
    frameWorker.postMessage(pausePayload)
  }


  const setTimestamp = () => {
    const initialDate = getDateFromURL()
    if (!initialDate) {
      return
    }
    const newDate = new Date(newTimestamp)
    if (isNaN(newDate.getTime())) {
      setError(`timestamp is not valid: ${newTimestamp}`)
      return
    }
    const roundedDate = roundDate(initialDate)
    if (newDate.getTime() < roundedDate.getTime() || newDate.getTime() > roundedDate.getTime() + (60 * 60 * 1000)) {
      setError(`timestamp is not within currently fetched range [${roundedDate.toISOString()} -> ${new Date(roundedDate.getTime() + (60 * 60 * 1000)).toISOString()}]`)
      return
    }

    const setDatePayload: SetDate = {
      type: FrameWorkerRequestMessage.setDate,
      date: newDate
    }
    frameWorker.postMessage(setDatePayload)
  }

  useEffect(() => {
    const speedString = query.get("speed")
    if (!speedString) {
      return
    }
    const speedI = parseFloat(speedString)
    if (isNaN(speedI)) {
      return
    }
    setSpeed(speedI)
  }, []);

  useEffect(() => {
    const vpidString = query.get("vision_program_id")
    if (!vpidString) {
      setError("No vision_program_id provided")
      return
    }
    const vpidI = parseInt(vpidString, 10)
    if (isNaN(vpidI)) {
      setError(`vision_program_id is not a number: ${vpidString}`)
      return
    }
    const fetchImage = async (vpid: number) => {
      // TODO: Sort out error handling - maybe a placeholder image too?
      if (isLocal()) {
        setImageUrl("./snappiPlaceholder.jpeg")
        return
      }
      const response = await fetch(`${getSnappiHost()}/snappi/${vpid}`, {
        credentials: "include",
        mode: "cors",
      })
      if (!response.body) {
        console.log("oops")
      }
      if (response.status !== 200) {
        console.log("oops")
      }
      const blob = await response.blob();
      const newBlob = new Blob([blob]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const image = await fetch(blobUrl);
      setImageUrl(image.url)
    }
    fetchImage(vpidI);
  }, []);


  if (!imageUrl || !currentFrame) {
    if (error) {
      return (
        <div>
          ERROR: {error}
        </div>
      )
    }
    return (
      <Loading/>
    )
  }

  return (
    <div className="App">
      <Stage options={{width: 1920, height: 1080}}>
        <Sprite texture={PIXI.Texture.from(imageUrl)} width={1920} height={1080}/>
        {currentFrame.detections.map((detection, i) =>
          <DetectionBox key={`detection_${i}`} color={colourMap[detection.classId]} text={classMap[detection.classId]}
                        points={[
                          denormalisePoint({x: detection.topLeftX, y: detection.topLeftY}),
                          denormalisePoint({x: detection.bottomRightX, y: detection.topLeftY}),
                          denormalisePoint({x: detection.bottomRightX, y: detection.bottomRightY}),
                          denormalisePoint({x: detection.topLeftX, y: detection.bottomRightY}),
                          denormalisePoint({x: detection.topLeftX, y: detection.topLeftY}),
                        ]}/>
        )}
      </Stage>
      <div>
        {currentFrame.timestamp.toISOString()}
      </div>
      <button onClick={play}>Play</button>
      <button onClick={pause}>Pause</button>
      <form onSubmit={(e) => e.preventDefault()}>
        <label>
          Speed:
          <input type="number" value={speed} step={0.1} onChange={(e) => {
            try {
              const parsed = parseFloat(e.target.value)
              if (parsed) {
                setSpeed(parsed)
              }
            } catch (e) {
            }
          }}/>
        </label>
      </form>
      <form onSubmit={(e) => e.preventDefault()}>
        <label>
          Timestamp:
          <input type="string" value={newTimestamp} onChange={(e) => {
            setNewTimestamp(e.target.value)
          }}/>
          <input type="submit" value="Set Timestamp" onClick={setTimestamp}/>
        </label>
      </form>
      {error &&
        <div>
          ERROR: {error}
        </div>
      }
    </div>
  );
}

export default Playback;
