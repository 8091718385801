import React, {useEffect, useState} from 'react';
import {Configuration, FrontendApi, LoginFlow, LogoutFlow, Session} from "@ory/client";

import Login from "./Login";
import Loading from "./Loading";
import Playback from "./Playback";
import {isLocal} from "./utils";

import './App.css';

const getOryBasePath = () => {
  if (window.location.hostname.includes("localhost")) {
    return "http://localhost:4000"
  } else if (window.location.hostname.includes("dev.vivacitylabs.com")) {
    return "https://auth.dev.vivacitylabs.com"
  } else if (window.location.hostname.includes("staging.vivacitylabs.com")) {
    return "https://auth.staging.vivacitylabs.com"
  } else {
    return "https://auth.vivacitylabs.com"
  }
}
const oryBasePath = getOryBasePath()

const ory = new FrontendApi(
  new Configuration({
    basePath: oryBasePath,
    baseOptions: {
      withCredentials: true,
    },
  }),
)

function App() {
  const [session, setSession] = useState<Session | undefined>()
  const [logoutFlow, setLogoutFlow] = useState<LogoutFlow | undefined>()
  const [loginFlow, setLoginFlow] = useState<LoginFlow | undefined>()

  useEffect(() => {
    if (isLocal()) {
      return
    }
    ory
      .toSession()
      .then(({data}) => {
        setSession(data)
        ory.createBrowserLogoutFlow().then(({data: flow}) => {
          setLogoutFlow(flow)
        })
      })
      .catch((err) =>
        ory.createBrowserLoginFlow({returnTo: window.location.href}).then(({data: flow}) => {
          setLoginFlow(flow)
        })
      )
      .catch((err) =>
        console.log(err)
      )
  }, [])

  if (isLocal()) {
    return (
      <div className="App">
        <Playback/>
      </div>
    )
  }

  if (!loginFlow && !logoutFlow) {
    return (
      <div className="App">
        <Loading/>
      </div>
    )
  }

  if (!session && loginFlow) {
    return (
      <div className="App">
        <Login loginFlow={loginFlow}/>
      </div>
    )
  }

  if (logoutFlow) {
    return (
      <div className="App">
        <Playback/>
      </div>
    );
  }
  return (
    <div className="App">
      <div>Ooops... Not sure what's going on... Try reloading!</div>
    </div>
  )
}

export default App;

// const signOut = async () => {
//   await fetch(logoutFlow?.logout_url!, {
//     mode: "cors",
//     credentials: "include",
//     redirect: "manual"
//   })
//   window.location.replace(window.origin)
// }