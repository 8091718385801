import {CustomPIXIComponent} from "react-pixi-fiber/index";
import * as PIXI from "pixi.js";

const textStyle = new PIXI.TextStyle({
  fontFamily: 'Arial', // The font family
  fontSize: 20, // The size of the font in pixels
  // fontStyle: 'italic', // The font style ('normal', 'italic' or 'oblique')
  // fontWeight: 'bold', // The font weight ('normal', 'bold', etc.)
  // fill: ['#ffffff', '#00ff99'], // A list of colors for the gradient; can be a single color as well
  // stroke: '#4a1850', // The color of the stroke around the text
  // strokeThickness: 5, // The thickness of the stroke. Set to 0 to have no stroke
});


const TYPE = "DetectionBox"
const behavior = {
  customDisplayObject: () => new PIXI.Graphics(),
  customApplyProps: (instance: PIXI.Graphics, oldProps: any, newProps: any) => {
    const {color, text, points} = newProps;

    instance.clear();
    instance.children.forEach(c => instance.removeChild(c));

    if (!points.length) {
      return;
    }

    instance.lineStyle(6, color);
    const [start, ...rest] = points;
    instance.moveTo(start.x, start.y);
    let i = 1;
    // @ts-ignore
    rest.forEach(({x, y}) => {
      instance.lineTo(x, y);
      i += 1;
    });
    const textObject = new PIXI.Text(text, new PIXI.TextStyle(textStyle));
    textObject.x = start.x + 3;
    textObject.y = start.y + 3;
    // @ts-ignore
    instance.addChild(textObject);

  },
};

// @ts-ignore
export default CustomPIXIComponent(behavior, TYPE);
