const NORMALISED_PIXELS = 16383;
export const SENSOR_VIEW_IMAGE_HEIGHT = 1080;
export const SENSOR_VIEW_IMAGE_WIDTH = 1920;
const denormaliseWidth = (x: number) =>
  Math.round((x / NORMALISED_PIXELS) * SENSOR_VIEW_IMAGE_WIDTH);
const denormaliseHeight = (y: number) =>
  Math.round((y / NORMALISED_PIXELS) * SENSOR_VIEW_IMAGE_HEIGHT);
export const denormalisePoint = (point: { x: number; y: number }) => {
  return {
    x: denormaliseWidth(point.x),
    y: denormaliseHeight(point.y),
  };
};
export const colourMap: { [key: number]: number } = {
  1: 0x00a0df,
  2: 0x2e3c99,
  3: 0x93ba4a,
  4: 0xc73f6d,
  5: 0xffc8cf,
  6: 0x97a2ad,
  7: 0x6b9a4a,
  8: 0x477d49,
  9: 0x78848e,
  10: 0x5b6670,
  11: 0xa02a4d,
  12: 0x78152e,
  13: 0x51000e,
  14: 0x91e7ff,
  15: 0x61cff4,
  16: 0x30b8ea,
  17: 0x007cb2,
  18: 0x005784,
  19: 0x003357,
  20: 0x1a227f,
  21: 0x00005d,
  22: 0xec9aaf,
  23: 0xda6d8e,
  24: 0xd7e3ef,
  25: 0xb6c2ce,
  26: 0x246149,
  27: 0x004448,
  28: 0x0c171f,
  29: 0x0c171f,
  30: 0x0c171f,
  31: 0x0c171f,
  32: 0x0c171f,
};
export const classMap: { [key: number]: string } = {
  0: "UNKNOWN_CLASS_TYPE",
  1: "PEDESTRIAN",
  2: "CYCLIST",
  3: "MOTORBIKE",
  4: "CAR",
  5: "TAXI",
  6: "VAN",
  7: "MINIBUS",
  8: "BUS",
  9: "RIGID",
  10: "TRUCK",
  11: "EMERGENCY_CAR",
  12: "EMERGENCY_VAN",
  13: "FIRE_ENGINE",
  14: "WHEELCHAIR",
  15: "MOBILITY_SCOOTER",
  16: "PUSHCHAIR",
  17: "JOGGER",
  18: "E_SCOOTER",
  19: "PUSH_SCOOTER",
  20: "RENTAL_BIKE",
  21: "CARGO_COURIER_BIKE",
  22: "TAXI_ELECTRIC",
  23: "TAXI_OTHER",
  24: "VAN_SMALL",
  25: "VAN_LUTON",
  26: "BUS_COACH",
  27: "BUS_LONDON",
  28: "TOWED_TRAILER",
  29: "TRACTOR",
  30: "AGRICULTURAL_VEHICLE",
  31: "HORSE_RIDER",
  32: "DOG",
  60: "LICENSE_PLATE",
  70: "POWERED_WATERCRAFT",
  71: "UNPOWERED_WATERCRAFT",
  100: "PLAYER",
  101: "MAINTENANCE_VEHICLE",
  102: "LAWNMOWER",
}
export type Frame = {
  timestamp: Date;
  detections: Detection[];
}
export type Detection = {
  trackNumber: number;
  topLeftX: number;
  topLeftY: number;
  bottomRightX: number;
  bottomRightY: number;
  classId: number;
}

export enum FrameWorkerRequestMessage {
  fetchDTFs = "fetchDTFs",
  play = "play",
  pause = "pause",
  setSpeed = "setSpeed",
  setDate = "setDate",
}

export type FetchDTFs = {
  type: FrameWorkerRequestMessage.fetchDTFs,
  vpid: number,
  date: Date
}

export type Play = {
  type: FrameWorkerRequestMessage.play,
}

export type Pause = {
  type: FrameWorkerRequestMessage.pause,
}

export type SetSpeed = {
  type: FrameWorkerRequestMessage.setSpeed,
  speed: number,
}

export type SetDate = {
  type: FrameWorkerRequestMessage.setDate,
  date: Date,
}

export enum FrameWorkerResponseMessage {
  currentFrame = "currentFrame",
}

export type CurrentFrame = {
  type: FrameWorkerResponseMessage.currentFrame,
  frame: Frame,
}

export const isLocal = () => window.location.hostname.includes("localhost")

export const getSnappiHost = () => {
  if (isLocal()) {
    return "http://localhost:8002"
  } else if (window.location.hostname.includes("dev.vivacitylabs.com")) {
    return "https://snappi.dtf-playback.dev.vivacitylabs.com"
  } else if (window.location.hostname.includes("staging.vivacitylabs.com")) {
    return "https://snappi.dtf-playback.staging.vivacitylabs.com"
  }
  return "https://snappi.dtf.vivacitylabs.com"
}